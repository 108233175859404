import React, {FC, useState} from "react"
import { MonitorHeart, QuestionMark, Thermostat } from "@mui/icons-material";
import {
  Chip,
  Typography,
} from "@mui/material";


export const ImplantHwVersionIcon: FC<{hwVersion: string}> = ({hwVersion}) => {
  const [editOpen, setEditOpen] = useState(false);

  return (
    <Chip variant="outlined" icon={hwVersion == "unknown" ? <QuestionMark/> : hwVersion.toLowerCase().includes("temperature") ? <Thermostat/> : <MonitorHeart/>} label={hwVersion}/>
  );

}
