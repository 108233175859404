import { Avatar, useTheme } from "@mui/material";
// import ColorHash from "color-hash-ts";
import { FC } from "react";

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringColor(email: string) {
  let name = email.toUpperCase().split("@")[0].replace(".", " ");
  const customHash = (str: string) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash += str.charCodeAt(i);
    }
    return hash;
  };
  // const colorHash = new ColorHash({lightness: 0.5});
  return stringToColor(name);

  // return colorHash.hex(name);
}

export function stringAvatar(email: string) {
  let name = email.toUpperCase().split("@")[0].replace(".", " ");

  return `${(name.split(" ")[0] ?? [""])[0] ?? ""}${
    (name.split(" ")[1] ?? [""])[0] ?? ""
  }`;
}

// thanks chatgpt
const generateHexColor = (input: string, isLight: boolean): string => {
  const hashCode = (str: string): number => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  };

  const intToRGB = (i: number): string => {
    const c = (i & 0x00ffffff).toString(16).toUpperCase();
    return "00000".substring(0, 6 - c.length) + c;
  };

  const hash = hashCode(input);
  const color = intToRGB(hash);

  if (isLight) {
    return `#${color}`;
  } else {
    // Apply logic to generate a darker shade of the color
    // For example, you can subtract a fixed value from each RGB component
    // to darken the color.
    // Modify the logic according to your specific requirements.
    return `#${color}`;
  }
};

export const UserAvatar: FC<{ email: string }> = ({ email }) => {
  const theme = useTheme();

  return (
    <Avatar
      children={stringAvatar(email)}
      sx={{ bgcolor: generateHexColor(email, theme.palette.mode === "light") }}
    />
  );
};
