import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbarQuickFilter,
  GridLinkOperator,
} from "@mui/x-data-grid";
import {
  LocalHospital,
  Person,
  Security,
  SentimentDissatisfiedOutlined,
  SupervisorAccount,
} from "@mui/icons-material";
import {
  Grid,
  Box,
  Divider,
  Stack,
  Typography,
  Icon,
  Paper,
  Skeleton,
  Link,
  Chip,
  Tabs,
  Tab,
  LinearProgress,
} from "@mui/material";

import { formatDate, formatSexString } from "../Utils";
import { useGroupAnimals } from "../../hooks/group/use-group-animals";
import { useGroupDetails } from "../../hooks/group/use-group-details";
import { usePreviousGroups } from "../../hooks/use-prev-groups";
import { CopyText } from "../Common/CopyText";
import { TabPanel, a11yProps } from "../Common/Tabs";
import { ObjectLog } from "../Common/ObjectLogs";
import { LinkGroupAnimalButton } from "./LinkGroupAnimalButton";
import { RemoveGroupAnimalButton } from "../Widgets/RemoveGroupAnimalButton";
import { AddGroupUserButton } from "../Widgets/AddGroupUserButton";
import { GroupSurveyDetails } from "./GroupSurveyDetails";

const userColumns: GridColDef[] = [
  {
    field: "email",
    headerName: "Email",
    flex: 2,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }} component="div">
          <CopyText>{params.value}</CopyText>
        </Typography>
      );
    },
  },
  {
    field: "username",
    headerName: "ID",
    flex: 2,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }} component="div">
          <CopyText>{params.value}</CopyText>
        </Typography>
      );
    },
  },

  {
    field: "created",
    headerName: "Date Added",
    flex: 1.5,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {formatDate(params.value)}
        </Typography>
      );
    },
  },
  {
    field: "access",
    headerName: "Access",
    flex: 1.5,
    renderCell: (params: GridRenderCellParams) => {
      switch (params.value) {
        case "owner":
          return (
            <Stack direction="row" spacing={2}>
              <Icon>
                <Security />
              </Icon>
              <Typography>Owner</Typography>
            </Stack>
          );

        case "admin":
          return (
            <Stack direction="row" spacing={2}>
              <Icon>
                <SupervisorAccount />
              </Icon>
              <Typography>Admin</Typography>
            </Stack>
          );
        default:
          return (
            <Stack direction="row" spacing={2}>
              <Icon>
                <Person />
              </Icon>
              <Typography>Member</Typography>
            </Stack>
          );
      }
    },
  },
];

export const animalColumns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 2,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "id",
    headerName: "ID",
    flex: 2.5,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }} component="div">
          <CopyText>{params.value}</CopyText>
        </Typography>
      );
    },
  },
  {
    field: "species",
    headerName: "Species",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "breed",
    headerName: "Breed",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "sex",
    headerName: "Sex",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {formatSexString(params.value)}
        </Typography>
      );
    },
  },
  {
    field: "created",
    headerName: "Created",
    flex: 1.5,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {formatDate(params.value)}
        </Typography>
      );
    },
  },
  {
    field: "groupId",
    headerName: "",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <RemoveGroupAnimalButton
          animalID={params.row.id}
          groupID={params.value}
          onDone={() => {}}
        />
      );
    },
  },
];

function QuickSearchToolbar(props: {
  groupId: string;
  groupName: string;
  members: boolean | null;
}) {
  return (
    <Stack
      sx={{
        p: 0.5,
        pb: 0,
      }}
      direction="row"
      justifyContent="space-between"
    >
      <GridToolbarQuickFilter
        // sx={{ maxWidth: "30%" }}
        quickFilterParser={(searchInput: string) =>
          searchInput
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "")
        }
      />
      <Stack direction="row">
        {props.groupId && !props.members && (
          <LinkGroupAnimalButton
            groupId={props.groupId}
            groupName={props.groupName}
          />
        )}
        {props.members && (
          <AddGroupUserButton
            groupId={props.groupId}
            groupName={props.groupName}
          />
        )}
      </Stack>
    </Stack>
  );
}

export default function GroupDetails() {
  const { groupid } = useParams();
  const { append } = usePreviousGroups();

  let navigate = useNavigate();

  const { groupDetails, loading } = useGroupDetails(groupid);

  const { groupAnimals, loading: loadingAnimals } = useGroupAnimals(groupid);

  const [value, setValue] = useState(0);

  useEffect(() => {
    if (groupDetails) {
      append(groupDetails.groupId, {
        groupId: groupDetails.groupId,
        name: groupDetails.name,
        created: groupDetails.created,
        users: groupDetails.users,
        animalCount: groupDetails.animalCount,
        organisation: {
          name: "",
          orgId: "",
        },
      });
    }
  }, [append, groupDetails, groupid]);

  return (
    <Stack spacing={2} sx={{ m: 1 }} height="100%" justifyContent="stretch">
      {loading ? (
        <Box sx={{ m: 2 }}>
          <Skeleton width="30%" height="2rem" />
          <Skeleton width="15%" height="0.8rem" />
          <Skeleton width="15%" />
        </Box>
      ) : groupDetails ? (
        <Box sx={{ m: 2 }}>
          <Typography variant="h5" color="inherit" justifyContent="center">
            {`${groupDetails.name}`}
          </Typography>
          <Typography variant="body2" component="div">
            {groupid ? (
              <CopyText>{groupid}</CopyText>
            ) : (
              <em>no ID specified</em>
            )}
          </Typography>

          <Typography variant="subtitle2">
            Organisation:{" "}
            {groupDetails.organisation?.name ? (
              <Link
                href={`/orgs/${groupDetails.organisation.orgId}`}
                underline="none"
                variant="subtitle1"
              >
                {groupDetails.organisation.name}{" "}
                {groupDetails.organisation.vetIntegration ? (
                  <Chip
                    sx={{ marginLeft: "0.5rem" }}
                    icon={<LocalHospital />}
                    variant="outlined"
                    color="info"
                    label={groupDetails.organisation.vetIntegration}
                  />
                ) : null}
              </Link>
            ) : (
              <em>no organisation linked</em>
            )}
          </Typography>
          <Typography variant="subtitle1">
            {groupDetails.animalCount ?? ""} animal
            {groupDetails.animalCount === 1 ? "" : "s"}
          </Typography>
        </Box>
      ) : (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          width="100%"
          marginTop="5rem"
        >
          <SentimentDissatisfiedOutlined
            color="error"
            sx={{ fontSize: "60pt", mr: 1 }}
          />
          <Typography variant="h3" color="error">
            Group Not Found
          </Typography>
        </Stack>
      )}
      {loading ? (
        <Box>
          <Divider />
          <Grid container spacing={2} sx={{ m: 2, p: 2 }}>
            <Grid item minWidth={"48%"}>
              <Skeleton variant="rectangular" height="20rem" />
            </Grid>
            <Grid item minWidth={"48%"}>
              <Skeleton variant="rectangular" height="20rem" />
            </Grid>
          </Grid>
        </Box>
      ) : (
        groupDetails && (
          <>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={(evt, idx) => setValue(idx)}
                aria-label=""
              >
                <Tab label="Implants" {...a11yProps(0)} />
                <Tab label="Group Members" {...a11yProps(0)} />
                <Tab label="Edit Logs" {...a11yProps(0)} />
                <Tab label="Survey Details" {...a11yProps(0)} />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0} flex="1" m={0} pb={2}>
              <Paper sx={{ p: 2, height: "100%" }}>
                {loadingAnimals ? (
                  <LinearProgress />
                ) : (
                  <>
                    <DataGrid
                      columns={animalColumns}
                      rows={groupAnimals.map((implant) => ({
                        id: implant.animalId,
                        key: implant.animalId,
                        groupId: groupid,
                        ...implant.animal,
                      }))}
                      initialState={{
                        filter: {
                          filterModel: {
                            items: [],
                            quickFilterLogicOperator: GridLinkOperator.Or,
                          },
                        },
                      }}
                      components={{ Toolbar: QuickSearchToolbar }}
                      componentsProps={{
                        toolbar: {
                          groupId: groupid,
                          groupName: groupDetails.name,
                        },
                      }}
                      onRowClick={(params) => {
                        navigate(
                          { pathname: `/implant/${params.id}` },
                          { replace: false }
                        );
                      }}
                    />
                  </>
                )}
              </Paper>
            </TabPanel>
            <TabPanel value={value} index={1} flex="1" m={0} pb={2}>
              <Paper sx={{ p: 2, height: "100%" }}>
                <>
                  <DataGrid
                    columns={userColumns}
                    rows={
                      groupDetails?.users?.map(({ user, access, created }) => ({
                        id: user?.username,
                        key: user?.username,
                        ...user,
                        access,
                        created,
                      })) ?? []
                    }
                    initialState={{
                      filter: {
                        filterModel: {
                          items: [],
                          quickFilterLogicOperator: GridLinkOperator.Or,
                        },
                      },
                    }}
                    components={{ Toolbar: QuickSearchToolbar }}
                    componentsProps={{
                      toolbar: {
                        groupId: groupid,
                        groupName: groupDetails.name,
                        members: true,
                      },
                    }}
                    onRowClick={(params) => {
                      navigate(
                        {
                          pathname: `/users/professional/${params.id}/${params.row.email}`,
                        },
                        { replace: false }
                      );
                    }}
                  />
                </>
              </Paper>
            </TabPanel>
            <TabPanel
              value={value}
              index={2}
              flex="1 1"
              overflow="auto"
              m={0}
              pb={2}
            >
              <Paper sx={{ p: 2, height: "100%" }}>
                {groupid && <ObjectLog type="group" id={groupid} />}
              </Paper>
            </TabPanel>

            <TabPanel
              value={value}
              index={3}
              flex="1 1"
              overflow="auto"
              m={0}
              pb={2}
            >
              <GroupSurveyDetails groupDetails={groupDetails} />
            </TabPanel>
          </>
        )
      )}
    </Stack>
  );
}
