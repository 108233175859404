import { FC } from "react";
import { useMutation } from "../../hooks/use-mutation";
import { ToggleRow } from "./ToggleRow";

export const ToggleUserRoleRow: FC<{
  label: string;
  username: string;
  role: string;
  defaultChecked?: boolean;
  onDone?: () => void;
}> = ({ label, defaultChecked = false, username, role, onDone }) => {
  const { execute } = useMutation(
    `mutation enableUserRole($username: ID!, $role: String!, $enabled: Boolean!, $userpool: UserPool!) {
      adminEnableUserRole(username: $username, role: $role, enabled: $enabled, userpool: $userpool) {
        name
        roles
        username
        email
        enabled
        organisations {
          access
          orgId
          organisation {
            name
            vetIntegration
            created
            updated
          }
        }
        groups {
          access
          group {
            name
            created
            groupId
            animalCount
            ezyVetLinked
            organisation {
              name
              orgId
              vetIntegration
            }
          }
        }
        animals {
          animal {
            animalId
            name
            species
            updated
            created
          }
        }
      }
    }`,
    {
      userpool: "professional",
      username,
      role,
    }
  );

  return (
    <ToggleRow
      label={label}
      defaultChecked={defaultChecked}
      onChange={async (enabled) => {
        await execute({ enabled });
        await new Promise((resolve) => setTimeout(resolve, 200));
        onDone && onDone();
      }}
    />
  );
};
