import { useState, useMemo } from "react";
import { ApolloClient, gql, useApolloClient } from "@apollo/client";
import { AnimalReading } from "./types";
import { sub } from "date-fns";

export const loadReadingHistoryQuery = gql`
  query getAnimalReadingHistory(
    $animalId: String!
    $type: String!
    $from: AWSDateTime
  ) {
    getAnimalReadingHistory(animalId: $animalId, type: $type, from: $from) {
      items {
        type
        booleanValue
        datetime
        floatValue
        intValue
        stringValue
      }
      nextToken
    }
  }
`;

const loadReadings = async (
  apollo: ApolloClient<any>,
  animalId: string,
  type: string
) => {
  let nextToken: string | undefined;
  let ret: AnimalReading[] = [];
  // do {
  const result = await apollo.query({
    query: loadReadingHistoryQuery,
    variables: {
      animalId,
      type,
      from: sub(Date.now(), { months: 12 }).toISOString(),
      nextToken,
    },
  });
  ret = [...ret, ...(result.data?.getAnimalReadingHistory?.items ?? [])];
  nextToken = result.data?.getAnimalReadingHistory?.nextToken;
  // } while (nextToken != null);
  // console.debug("Query result", type, ret);
  return ret;
};

export const useAnimalReadingsType = (animalId: string, type: string) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [readings, setReadings] = useState<AnimalReading[]>([]);
  const apollo = useApolloClient();

  useMemo(async () => {
    setReadings(await loadReadings(apollo, animalId, type));
    setLoading(false);
  }, [apollo, animalId, type]);

  const reload = useMemo(
    () => async () => {
      setLoading(true);
      setReadings(await loadReadings(apollo, animalId, type));
      setLoading(false);
    },
    [apollo, animalId, type]
  );

  return {
    loading,
    readings,
    reload,
  };
};
