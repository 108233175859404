import { FC, useState } from "react";

import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";

import {
  AutoGraphOutlined,
  Battery0Bar,
  MonitorHeartOutlined,
  ThermostatOutlined,
} from "@mui/icons-material";

import { useAnimalReadingsType } from "../../hooks/animal/use-animal-readings";
import { Animal } from "./ImplantDetails";
import { SynchronisedReadingsChart } from "../Widgets/HistoricalReadingChart";
import { formatReadingType } from "../Utils";
import { HistoricalRangeBarChart } from "../Widgets/HistoricalRangeBarChart";

export interface ImplantReadingGraphsProps {
  animal: Animal;
}

export const ImplantReadingGraphs: FC<ImplantReadingGraphsProps> = ({
  animal,
}) => {
  const temperature = useAnimalReadingsType(animal.animalId, "temperature");
  const heartRate = useAnimalReadingsType(animal.animalId, "heartRate");
  const batteryPercent = useAnimalReadingsType(
    animal.animalId,
    "batteryPercent",
  );
  const hrv = useAnimalReadingsType(animal.animalId, "heartRateVariance");

  const [showAllReadings, setShowAllReadings] = useState(true);
  const [showBadReadings, setShowBadReadings] = useState<boolean>(false);
  const [value, setValue] = useState<number>(0);

  return (
    <>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <ButtonGroup
          variant={value === 0 ? "contained" : "outlined"}
          fullWidth={false}
          disableElevation
          aria-label="outlined primary button group"
          sx={{ p: 2 }}
        >
          <Button
            disabled={showAllReadings}
            // variant="contained"
            startIcon={<MonitorHeartOutlined />}
            onClick={() => {
              setValue(0);
            }}
          >
            Heart Rate
          </Button>

          <Button
            disabled={showAllReadings}
            variant={value === 3 ? "contained" : "outlined"}
            startIcon={<AutoGraphOutlined />}
            onClick={() => {
              setValue(3);
            }}
          >
            HRV
          </Button>
          <Button
            disabled={showAllReadings}
            variant={value === 1 ? "contained" : "outlined"}
            startIcon={<ThermostatOutlined />}
            onClick={() => {
              setValue(1);
            }}
          >
            Temperature
          </Button>
          <Button
            disabled={showAllReadings}
            variant={value === 2 ? "contained" : "outlined"}
            startIcon={<Battery0Bar />}
            onClick={() => {
              setValue(2);
            }}
          >
            Battery
          </Button>
          <Button
            variant={showAllReadings ? "contained" : "outlined"}
            onClick={(value) => {
              setShowAllReadings(!showAllReadings);
            }}
          >
            {" "}
            Combined
          </Button>
        </ButtonGroup>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(event) => setShowBadReadings(event.target.checked)}
            />
          }
          label="Show NaN readings"
        />
      </Stack>
      <Box height="100%">
        {!showAllReadings
          ? (
            <Box height="100%">
              <Typography textAlign="center" variant="h6">
                {formatReadingType(
                  value === 0
                    ? "heartRate"
                    : value === 1
                      ? "temperature"
                      : value === 2
                        ? "batteryPercent"
                        : value === 3
                          ? "hrv"
                          : "",
                )}
              </Typography>
              <HistoricalRangeBarChart
                readings={value === 0
                  ? heartRate.readings
                  : value === 1
                    ? temperature.readings
                    : value === 2
                      ? batteryPercent.readings
                      : value === 3
                        ? hrv.readings
                        : []}
              />
            </Box>
          )
          : (
            <SynchronisedReadingsChart
              showBadReadings={showBadReadings}
              heartRate={heartRate.readings}
              hrv={hrv.readings}
              temperature={temperature.readings}
              batteryPercent={batteryPercent.readings}
            />
          )}
      </Box>
    </>
  );
};
