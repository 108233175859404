import { gql, useQuery, useSubscription } from "@apollo/client";
import { useMemo } from "react";

export interface UserGroupAccess {
  access: string;
  group: {
    name: string;
    created: string;
    groupId: string;
    animalCount: string;
    ezyVetLinked: boolean;
    organisation: {
      name: string;
      orgId: string;
    };
  };
}

export interface UserAnimalsAccess {
  animalId: string;
  animal: {
    animalId: string;
    name: string;
    species: string;
    updated: string;
    created: string;
  };
}

export interface UserOrgAccess {
  access: string;
  orgId: string;
  organisation: {
    name: string;
    vetIntegration?: string;
    created: string;
    updated: string;
  };
}

export interface UserSetting {
  created: string;
  owner: string;
  setting: string;
  value: string;
}

export interface UserDetails {
  username: string;
  name: string;
  email: string;
  enabled: boolean;
  roles: string[];
  status: string;
  groups?: UserGroupAccess[];
  animals?: UserAnimalsAccess[];
  organisations: UserOrgAccess[];
  settings?: UserSetting[];
}

export const adminGetUserDetailsQuery = `
query AdminSearchUser($username: String, $pool: UserPool!) {
  adminSearchUser(userpool: $pool, username: $username) {
    name
    roles
    username
    email
    enabled
    status
    organisations {
      access
      orgId
      organisation {
        name
        vetIntegration
        created
        updated
      }
    }
    groups {
      access
      group {
        name
        created
        groupId
        animalCount
        ezyVetLinked
        organisation {
          name
          orgId
          vetIntegration
        }
      }
    }
    settings {
      created
      owner
      setting
      value
    }
    animals {
      animal {
        animalId
        name
        species
        updated
        created
      }
    }
  }
}
`;

export const useUserDetails = (
  userId?: string,
  pool?: string,
  email?: string
) => {
  const { data, refetch, ...query } = useQuery(gql(adminGetUserDetailsQuery), {
    variables: { username: userId, pool },
  });

  useSubscription(
    gql`
      subscription GroupEventSubscription($owner: String!) {
        onGroupEventByOwner(owner: $owner) {
          group {
            animalCount
            created
            groupId
            ezyVetLinked
            name
          }
        }
      }
    `,
    {
      variables: {
        owner: userId,
      },
      onData: (data) => {
        refetch();
      },
    }
  );

  useSubscription(
    gql`
      subscription RemoveGroupEventSubscription($owner: String!) {
        onRemoveGroupEventByOwner(owner: $owner) {
          owner
          groupId
          access
        }
      }
    `,
    {
      variables: {
        owner: userId,
      },
      onData: (data) => {
        refetch();
      },
    }
  );

  useSubscription(
    gql`
      subscription OnOrgEvent($owner: String!) {
        onOrganisationEventByOwner(owner: $owner) {
          access
          created
          orgId
          owner
          organisation {
            created
            name
            orgId
            updated
            vetIntegration
          }
        }
      }
    `,
    {
      variables: { owner: userId },
      onData: (data) => {
        refetch();
      },
    }
  );

  const userDetails: UserDetails = useMemo(() => {
    return data?.adminSearchUser?.[0] as UserDetails;
  }, [data]);

  return { userDetails, ...query, refresh: refetch };
};
