import { Stack } from "@mui/material";
import { GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";

export function QuickSearchToolbar() {
  return (
    <Stack
      spacing={1}
      direction="row"
      justifyContent="start"
      sx={{
        p: 0.5,
        pb: 0,
      }}
    >
      <Stack direction="row">
        <GridToolbarContainer sx={{ flex: 2 }}>
          <GridToolbarDensitySelector />
        </GridToolbarContainer>
        <GridToolbarContainer sx={{ flex: 2 }}>
          <GridToolbarFilterButton />
        </GridToolbarContainer>
        <GridToolbarContainer sx={{ flex: 2 }}>
          <GridToolbarExport />
        </GridToolbarContainer>
      </Stack>
    </Stack>
  );
}
