import { useQuery } from "../../graphql/Query";
import { GroupAnimal } from "./types";

export const adminGetAnimalGroupsQuery = `query AdminGetAnimalGroups($animalId: ID! ){
  adminGetAnimalGroups(animalId: $animalId) {
    access
    group {
      groupId
      name
      updated
      created
      organisation {
        name
        orgId
      }
    }
  }
}
`;

export const useAnimalGroups = (animalId?: string) => {
  const { data, ...query } = useQuery(adminGetAnimalGroupsQuery, { animalId });
  return {
    ...query,
    groups: (data?.adminGetAnimalGroups ?? []) as GroupAnimal[],
  };
};
