import { FC, useMemo } from "react";
import { startCase } from "lodash";
import { Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridLinkOperator,
  GridRenderCellParams,
} from "@mui/x-data-grid";

import { useAnimalReadingsType } from "../../hooks/animal/use-animal-readings";
import { formatDate } from "../Utils";
import { QuickSearchToolbar } from "../Common/QuickSearchToolbarReadings";

export const readingColumns: GridColDef[] = [
  {
    field: "type",
    headerName: "Type",
    type: "string",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {(
            startCase(params.value)
          )}
        </Typography>
      );
    },
  },
  {
    field: "floatValue",
    headerName: "Value",
    headerAlign: "left",
    type: "number",
    align: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography
          variant="body2"
          sx={{ cursor: "pointer" }}
          component={"span"}
        >
          {params.value == null
            ? "NaN"
              : params.row.type === "heartRate" ||
              params.row.type === "batteryPercent"
            ? params.value?.toFixed(0)
            : params.value?.toFixed(2)}
        </Typography>
      );
    },
  },

  {
    field: "datetime",
    headerName: "Date",
    type: "dateTime",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {formatDate(params.value)}
        </Typography>
      );
    },
  },
];


export interface ImplantReadingHistoryProps {
  animalId: string;
  type: string;
}
export const ImplantReadingHistory: FC<ImplantReadingHistoryProps> = ({
  animalId,
  type,
}) => {
  const { readings, loading } = useAnimalReadingsType(animalId, type);

  const rows = useMemo(
    () =>
      readings?.map((r) => ({
        id: r.datetime,
        key: r.datetime,
        ...r,
      })) ?? [],
    [readings]
  );

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <DataGrid
      columns={readingColumns}
      rows={rows}
      initialState={{
        filter: {
          filterModel: {
            items: [],
            quickFilterLogicOperator: GridLinkOperator.Or,
          },
        },
      }}
      components={{ Toolbar: QuickSearchToolbar }}
    />
  );
};
