import { Link, Skeleton, Stack, Typography } from "@mui/material";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridLinkOperator,
  GridRenderCellParams,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useAnimalGroups } from "../../hooks/animal/use-animal-groups";
import { LinkGroupToAnimalButton } from "./LinkGroupToAnimalButton";
import { RemoveGroupAnimalButton } from "./RemoveGroupAnimalButton";

function QuickSearchToolbar(props: { animalId: string; animalName?: string }) {
  return (
    <Stack
      sx={{
        p: 0.5,
        pb: 0,
      }}
      direction="row"
      justifyContent="space-between"
    >
      <GridToolbarQuickFilter
        // sx={{ maxWidth: "30%" }}
        quickFilterParser={(searchInput: string) =>
          searchInput
            .split(",")
            .map((value) => value.trim())
            .filter((value) => value !== "")
        }
      />
      {props.animalId && (
        <LinkGroupToAnimalButton
          animalId={props.animalId}
          animalName={props.animalName}
        />
      )}{" "}
    </Stack>
  );
}

export const ImplantGroups: FC<{ animalId: string; animalName?: string }> = ({
  animalId,
  animalName,
}) => {
  const navigate = useNavigate();
  const { loading, groups, refresh } = useAnimalGroups(animalId);

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Group Name",
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography variant="body2" sx={{ cursor: "pointer" }}>
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "org",
      headerName: "Organisation",
      flex: 2,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link href={`/orgs/${params.value?.orgId}`} underline="none">
            <Typography variant="body2" sx={{ cursor: "pointer" }}>
              {params.value?.name}
            </Typography>
          </Link>
        );
      },
    },
    {
      field: "groupId",
      headerName: "",
      align: "center",
      flex: 2,
      renderCell: (params: GridRenderCellParams) => (
        <RemoveGroupAnimalButton
          animalID={animalId}
          groupID={params.value}
          onDone={() => {
            refresh();
          }}
        />
      ),
    },
  ];

  if (loading) {
    return <Skeleton variant="rectangular" height="30rem" sx={{ m: 3 }} />;
  }

  return (
    <DataGrid
      disableSelectionOnClick
      hideFooter
      columns={columns}
      rows={groups.map((group) => ({
        // id: group?.group?.groupId,
        key: group?.group?.groupId,
        access: group?.access,
        org: group?.group?.organisation,
        ...group?.group,
      }))}
      getRowId={(row: any) => `${row.groupId}|${row.name}`}
      components={{ Toolbar: QuickSearchToolbar }}
      componentsProps={{
        toolbar: {
          animalId,
          animalName,
        },
      }}
      initialState={{
        filter: {
          filterModel: {
            items: [],
            quickFilterLogicOperator: GridLinkOperator.Or,
          },
        },
      }}
      onCellClick={(params: GridCellParams) => {
        if (params.field === "name") {
          navigate(
            { pathname: `/groups/${params.id.toString().split("|")[0]}` },
            { replace: false }
          );
        }
      }}
    />
  );
};
